import Link from "next/link";
import { FC } from "react";

import { AnimatedIcon } from "../../components/Button";
import { classNames } from "@frec-js/common-web";
import Image from "next/image";
import { FrecWebPath } from "../../utils/paths";
import { useIsMobile } from "../../hooks/useIsMobile";
export const BannerMobileApp: FC<{ className?: string }> = ({ className }) => {
  const isMobile = useIsMobile();
  return (
    <Link
      target="_blank"
      href={FrecWebPath.mobileApp}
      className={classNames(
        "bg-frecNeon group flex h-9 w-full items-center justify-center gap-2 border-b !border-black px-2",
        className,
      )}
    >
      <span className="text-nowrap text-sm">
        The wait is over – the Frec iOS app is here.
      </span>
      <Image
        alt="rocket ship"
        src="/webstatic/svg/rocket-launch.svg"
        width={16}
        height={16}
      />
      <span className="flex items-center justify-center text-sm font-medium">
        {isMobile ? null : "See on the App Store"} <AnimatedIcon />
      </span>
    </Link>
  );
};
