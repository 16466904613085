import { borrowRatesAnchor } from "../components/Anchor";

export const BrokerageWebPath = {
  login: "/app/login",
  overview: "/app/overview",
  signup: "/app/signup",
  signupPlain: "/app/signup/plain",
  demo: "/app/demo/direct-indexing",
  forgetPassword: "/app/forget-password",
};

export const FrecWebPath = {
  about: "/about",
  blog: "/resources/blog",
  borrowRates: `/pricing#${borrowRatesAnchor}`,
  calculateTaxSavings: "/calculate-tax-savings",
  disclosures: "/disclosures",
  pricing: "/pricing",
  pricingBlog:
    "/resources/blog/comparing-frec-to-other-direct-indexing-providers",
  privacy: "/privacy",
  resources: "/resources",
  root: "/",
  rss: "/rss",
  sellvsborrow: "/sell-vs-borrow",
  taxLossHarvesting: "/tax-loss-harvesting",
  terms: "/terms",
  whitepaper: "/resources/blog/frec-direct-indexing-algorithm",
  whitepaperEtfs:
    "/resources/blog/tax-loss-harvesting-etf-based-v-s-direct-indexing",
  hundredmillion: "/resources/blog/100-million",
  mobileApp: "https://apps.apple.com/us/app/frec-direct-indexing/id6505110202",
};
