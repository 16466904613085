import Decimal from "decimal.js";
import {
  getNextDepositDate,
  getNextDepositDateOnly,
  validateScheduledDepositConfig,
  ValidateScheduledDepositConfigArgs,
  ValidateScheduledDepositConfigValidationResult,
} from "./scheduledDepositUtils";
import { ZERO } from "../utils";
import {
  AllocationConfigCashTransfer,
  AllocationConfigCashTransferInput,
  AllocationConfigType,
  ScheduledDepositConfig,
  ScheduledDepositPeriodType,
} from "../generated/graphql";
import { BUSINESS_TIMEZONE, DateOnly } from "../date_utils";

export const getTotalAllocationPercentage = (
  cashTransfers: {
    allocationSubAccountId: string;
    percentage: Decimal;
  }[],
  purchaseOrders: {
    securityId: string;
    percentage: Decimal;
  }[],
): Decimal => {
  return cashTransfers
    .reduce((acc, curr) => acc.plus(curr.percentage), ZERO)
    .plus(
      purchaseOrders.reduce((acc, curr) => acc.plus(curr.percentage), ZERO),
    );
};

export enum ValidateAllocationConfigResultEnum {
  PercentageSumInvalid = "PercentageSumInvalid",
  PercentageInvalid = "PercentageInvalid",
  PortfolioRebalancePurchaseOrdersPresent = "PortfolioRebalancePurchaseOrdersPresent",
  PublicApiCashTransfersPresent = "PublicApiCashTransfersPresent",
  ScheduledDepositConfigInvalid = "ScheduledDepositConfigInvalid",
  SubAccountIdsNotUnique = "SubAccountIdsNotUnique",
  SecurityIdsNotUnique = "SecurityIdsNotUnique",
  SourceSubAccountInCashTransfers = "SourceSubAccountInCashTransfers",
}

export type ValidateAllocationConfigResult = {
  allocationConfigErrors: ValidateAllocationConfigResultEnum[];
  scheduledDepositConfigErrors?: ValidateScheduledDepositConfigValidationResult[];
};

export type ValidateAllocationConfigArgs = {
  type: AllocationConfigType;
  cashTransfers: {
    allocationSubAccountId: string;
    percentage: Decimal;
  }[];
  purchaseOrders: {
    securityId: string;
    percentage: Decimal;
  }[];
  scheduledDepositConfig?: Omit<
    ValidateScheduledDepositConfigArgs,
    "subAccountId"
  >[];
};

export const validateAllocationConfig = (
  args: ValidateAllocationConfigArgs,
): ValidateAllocationConfigResult => {
  const results = new Set<ValidateAllocationConfigResultEnum>();

  // allocation percentages must be positive
  if (args.cashTransfers.some((c) => c.percentage.lte(ZERO))) {
    results.add(ValidateAllocationConfigResultEnum.PercentageInvalid);
  }

  if (args.purchaseOrders.some((p) => p.percentage.lte(ZERO))) {
    results.add(ValidateAllocationConfigResultEnum.PercentageInvalid);
  }

  // Allocation percentages must sum to 100%
  const totalPercentage = getTotalAllocationPercentage(
    args.cashTransfers,
    args.purchaseOrders,
  );
  if (!totalPercentage.eq(new Decimal(100))) {
    results.add(ValidateAllocationConfigResultEnum.PercentageSumInvalid);
  }

  // subAccountIds should be unique
  const subAccountIds = new Set(
    args.cashTransfers.map((c) => c.allocationSubAccountId),
  );
  if (subAccountIds.size !== args.cashTransfers.length) {
    results.add(ValidateAllocationConfigResultEnum.SubAccountIdsNotUnique);
  }
  // securityIds should be unique
  const securityIds = new Set(args.purchaseOrders.map((p) => p.securityId));
  if (securityIds.size !== args.purchaseOrders.length) {
    results.add(ValidateAllocationConfigResultEnum.SecurityIdsNotUnique);
  }

  // cash transfers should not be present if type is PUBLIC_API
  if (
    args.type === AllocationConfigType.PublicApi &&
    args.cashTransfers.length > 0
  ) {
    results.add(
      ValidateAllocationConfigResultEnum.PublicApiCashTransfersPresent,
    );
  }
  // orders should not be present if type is PORTFOLIO_REBALANCE
  if (
    args.type === AllocationConfigType.PortfolioRebalance &&
    args.purchaseOrders.length > 0
  ) {
    results.add(
      ValidateAllocationConfigResultEnum.PortfolioRebalancePurchaseOrdersPresent,
    );
  }

  // validate scheduled deposit configs
  let scheduledDepositConfigErrors:
    | ValidateScheduledDepositConfigValidationResult[]
    | undefined;
  if (args.scheduledDepositConfig) {
    args.scheduledDepositConfig.forEach((sd) => {
      const scheduledConfigResult = validateScheduledDepositConfig({
        ...sd,
        // All scheduled deposit configs for allocations are for the primary sub account
        subAccountId: "primarySubAccountId",
      });
      if (scheduledConfigResult.length > 0) {
        if (!scheduledDepositConfigErrors) {
          scheduledDepositConfigErrors = [];
        }
        scheduledDepositConfigErrors.push(...scheduledConfigResult);
        results.add(
          ValidateAllocationConfigResultEnum.ScheduledDepositConfigInvalid,
        );
      }
      // Source account in scheduled deposit should not be included in cash transfers
      if (
        sd.sourceSubAccountId !== undefined &&
        args.cashTransfers.some(
          (c) => c.allocationSubAccountId === sd.sourceSubAccountId,
        )
      ) {
        results.add(
          ValidateAllocationConfigResultEnum.SourceSubAccountInCashTransfers,
        );
      }
    });
  }

  return {
    allocationConfigErrors: Array.from(results),
    scheduledDepositConfigErrors,
  };
};

export type NormalizedAllocationConfig = Omit<
  ValidateAllocationConfigArgs,
  "scheduledDepositConfigs"
>;

/**
 * Normalizes the allocation config percentages to ensure that they sum to 100%
 */
export const normalizeAllocationConfig = (
  args: NormalizedAllocationConfig,
  decimalPlaces = 2,
): NormalizedAllocationConfig => {
  // if allocation doesn't sum to 100, normalize by percentage
  const totalPercentage = getTotalAllocationPercentage(
    args.cashTransfers,
    args.purchaseOrders,
  );

  // Create new arrays with normalized percentages
  let normalizedCashTransfers = args.cashTransfers;
  let normalizedPurchaseOrders = args.purchaseOrders;

  // ensure that this works for cases like 33 + 33 + 33
  // this is to prevent floating point precision issues
  if (!totalPercentage.eq(100)) {
    normalizedCashTransfers = args.cashTransfers.map((c) => ({
      ...c,
      percentage: c.percentage
        .div(totalPercentage)
        .mul(100)
        .toDP(decimalPlaces),
    }));
    normalizedPurchaseOrders = args.purchaseOrders.map((p) => ({
      ...p,
      percentage: p.percentage
        .div(totalPercentage)
        .mul(100)
        .toDP(decimalPlaces),
    }));
  }

  // Calculate the new total after normalization
  const newTotal = getTotalAllocationPercentage(
    normalizedCashTransfers,
    normalizedPurchaseOrders,
  );

  // If we're not exactly at 100%, adjust the largest allocation
  if (!newTotal.eq(100)) {
    const difference = new Decimal(100).minus(newTotal);
    const allAllocations = [
      ...normalizedCashTransfers,
      ...normalizedPurchaseOrders,
    ];
    const largest = allAllocations.reduce((max, curr) =>
      curr.percentage.gt(max.percentage) ? curr : max,
    );
    largest.percentage = largest.percentage.plus(difference);
  }

  return {
    ...args,
    cashTransfers: normalizedCashTransfers,
    purchaseOrders: normalizedPurchaseOrders,
  };
};

export type PortfolioAllocationValue = {
  cashTransfers: {
    subAccountId: string;
    value: Decimal;
  }[];
};

export type PortfolioAllocation = {
  cashTransfers: {
    allocationSubAccountId: string;
    percentage: Decimal;
  }[];
};

/**
 * Given a portfolio allocation value, determines the current allocation percentage
 */
export const determineAllocationPercentage = (
  allocationValue: PortfolioAllocationValue,
  decimalPlaces = 2,
): PortfolioAllocation => {
  const totalValue = allocationValue.cashTransfers.reduce(
    (acc, curr) => acc.plus(curr.value),
    ZERO,
  );

  // Handle floating point precision issues to make sure it adds up to 100%
  const cashTransfers = allocationValue.cashTransfers.map((c) => ({
    allocationSubAccountId: c.subAccountId,
    percentage: totalValue.eq(0)
      ? ZERO
      : c.value.div(totalValue).mul(100).toDP(decimalPlaces),
  }));
  // TODO: implement this for purchase orders
  const totalPercentage = getTotalAllocationPercentage(cashTransfers, []);

  // If we're not exactly at 100%, adjust the largest allocation
  if (!totalPercentage.eq(100) && !totalPercentage.isZero()) {
    const difference = new Decimal(100).minus(totalPercentage);
    const largest = cashTransfers.reduce((max, curr) =>
      curr.percentage.gt(max.percentage) ? curr : max,
    );
    largest.percentage = largest.percentage.plus(difference);
  }

  return {
    cashTransfers: cashTransfers,
  };
};

const filterPortfolioAllocationValue = (
  portfolioAllocationValue: PortfolioAllocationValue,
  targetAllocation: PortfolioAllocation,
): PortfolioAllocationValue => {
  return {
    ...portfolioAllocationValue,
    cashTransfers: portfolioAllocationValue.cashTransfers.filter((ct) =>
      targetAllocation.cashTransfers.some(
        (t) => t.allocationSubAccountId === ct.subAccountId,
      ),
    ),
  };
};

export type AllocationRebalanceAmount = {
  allocationSubAccountId: string;
  amountRequired: Decimal;
  targetPercentage: Decimal;
  currentPercentage: Decimal;
};

export const amountRequiredForRebalance = (
  currentAllocationValue: PortfolioAllocationValue,
  targetAllocation: PortfolioAllocation,
): AllocationRebalanceAmount[] => {
  // Filter out sub accounts that are not in the target allocation
  const filteredCurrentAllocationValue = filterPortfolioAllocationValue(
    currentAllocationValue,
    targetAllocation,
  );

  const currentSubAccountValueMap = new Map(
    filteredCurrentAllocationValue.cashTransfers.map((ct) => [
      ct.subAccountId,
      ct.value,
    ]),
  );

  const currentAllocationPercentageMap = new Map(
    determineAllocationPercentage(
      filteredCurrentAllocationValue,
    ).cashTransfers.map((ct) => [ct.allocationSubAccountId, ct.percentage]),
  );

  // Find the highest value account relative to its target percentage
  // This will be our reference point for calculating the target total
  let maxReferenceValue = ZERO;
  targetAllocation.cashTransfers.forEach((target) => {
    const currentValue =
      currentSubAccountValueMap.get(target.allocationSubAccountId) || ZERO;
    // Calculate what the total should be if this account was at its target percentage
    const totalNeeded = currentValue.mul(100).div(target.percentage).toDP(2);
    maxReferenceValue = Decimal.max(maxReferenceValue, totalNeeded);
  });

  return targetAllocation.cashTransfers.map((target) => {
    const currentValue =
      currentSubAccountValueMap.get(target.allocationSubAccountId) || ZERO;
    const currentPercentage =
      currentAllocationPercentageMap.get(target.allocationSubAccountId) || ZERO;
    const targetValue = maxReferenceValue
      .mul(target.percentage)
      .div(100)
      .toDP(2);
    const amountRequired = targetValue.minus(currentValue).toDP(2);

    return {
      allocationSubAccountId: target.allocationSubAccountId,
      amountRequired: amountRequired.gt(ZERO) ? amountRequired.round() : ZERO,
      targetPercentage: target.percentage,
      currentPercentage,
    };
  });
};

/**
 * Calculate the amount of each sub account to sell (negative value) and
 * buy (positive value) to bring the current allocation to the target allocation.
 */
export const buyAndSellToRebalance = (
  currentAllocationValue: PortfolioAllocationValue,
  targetAllocation: PortfolioAllocation,
): AllocationRebalanceAmount[] => {
  // Filter out sub accounts that are not in the target allocation
  const filteredCurrentAllocationValue = filterPortfolioAllocationValue(
    currentAllocationValue,
    targetAllocation,
  );

  const currentSubAccountValueMap = new Map(
    filteredCurrentAllocationValue.cashTransfers.map((ct) => [
      ct.subAccountId,
      ct.value,
    ]),
  );

  const currentAllocationPercentageMap = new Map(
    determineAllocationPercentage(
      filteredCurrentAllocationValue,
    ).cashTransfers.map((ct) => [ct.allocationSubAccountId, ct.percentage]),
  );

  const totalCurrentAllocationValue =
    filteredCurrentAllocationValue.cashTransfers.reduce(
      (acc, curr) => acc.plus(curr.value),
      ZERO,
    );

  // Calculate the difference between the current allocation and the target allocation values
  // This is the amount of each sub account to sell (negative value) or buy (positive value)
  // difference = targetValue at current allocation total - currentValue
  //            = targetPercentage * totalCurrentAllocationValue - currentValue
  return targetAllocation.cashTransfers.map((target) => {
    const currentValue =
      currentSubAccountValueMap.get(target.allocationSubAccountId) ?? ZERO;
    const currentPercentage =
      currentAllocationPercentageMap.get(target.allocationSubAccountId) ?? ZERO;
    const targetValue = target.percentage
      .mul(totalCurrentAllocationValue)
      .div(100)
      .toDP(2);
    return {
      allocationSubAccountId: target.allocationSubAccountId,
      amountRequired: targetValue.minus(currentValue),
      targetPercentage: target.percentage,
      currentPercentage,
    };
  });
};

export type DepositAllocation = AllocationRebalanceAmount & {
  depositShare: Decimal;
  depositsNeeded: Decimal;
};

export const distributeDepositAmountToRebalance = (
  currentAllocationValue: PortfolioAllocationValue,
  targetAllocation: PortfolioAllocation,
  depositAmount: Decimal,
): DepositAllocation[] => {
  // Filter out sub accounts that are not in the target allocation
  const filteredCurrentAllocationValue = filterPortfolioAllocationValue(
    currentAllocationValue,
    targetAllocation,
  );

  // Get the required amounts for each subAccount
  const rebalanceAmounts = amountRequiredForRebalance(
    filteredCurrentAllocationValue,
    targetAllocation,
  );

  // Calculate total rebalance amount needed
  const totalRebalanceNeeded = rebalanceAmounts.reduce(
    (sum, ra) => sum.plus(ra.amountRequired),
    ZERO,
  );
  const leftOver = totalRebalanceNeeded.gte(depositAmount)
    ? ZERO
    : depositAmount.minus(totalRebalanceNeeded);

  const amountLeft = depositAmount;

  return rebalanceAmounts.map((ra) => {
    // Calculate this account's share of each deposit based on its portion of total needed
    const rebalanceShare = totalRebalanceNeeded.eq(ZERO)
      ? ZERO
      : new Decimal(Decimal.min(ra.amountRequired, depositAmount))
          .mul(ra.amountRequired)
          .div(totalRebalanceNeeded)
          .toDP(2);

    const leftOverShare = leftOver.mul(ra.targetPercentage).div(100).toDP(2);

    const depositShare = rebalanceShare.plus(leftOverShare);

    return {
      allocationSubAccountId: ra.allocationSubAccountId,
      amountRequired: ra.amountRequired,
      targetPercentage: ra.targetPercentage,
      currentPercentage: ra.currentPercentage,
      depositShare,
      depositsNeeded: depositShare.gte(ra.amountRequired)
        ? ZERO
        : /**
           * Ideally Decimal.ceil would be used here, but it rounds up to the next integer
           * which is not always correct. For example, 1000/333.33=rounds up to 4
           * So we're using a manual rounding method instead.
           */
          ra.amountRequired.div(depositShare).toDP(0, Decimal.ROUND_HALF_CEIL),
    };
  });
};

export type RebalanceCompleteDate = {
  allocationSubAccountId: string;
  daysUntilCompletionFromToday: number;
  depositsNeeded: number;
  dateUntilCompletion: DateOnly | null;
};

export const getRebalanceCompleteDates = (
  currentAllocationValue: PortfolioAllocationValue,
  targetAllocation: PortfolioAllocation,
  scheduledDepositConfig: ScheduledDepositConfig,
): RebalanceCompleteDate[] => {
  // Filter out sub accounts that are not in the target allocation
  const filteredCurrentAllocationValue = filterPortfolioAllocationValue(
    currentAllocationValue,
    targetAllocation,
  );

  const depositAmounts = distributeDepositAmountToRebalance(
    filteredCurrentAllocationValue,
    targetAllocation,
    scheduledDepositConfig.amount,
  );

  return depositAmounts.map((ra) => {
    if (!ra.amountRequired.gt(ZERO)) {
      return {
        allocationSubAccountId: ra.allocationSubAccountId,
        daysUntilCompletionFromToday: 0,
        depositsNeeded: 0,
        dateUntilCompletion: null,
      };
    }
    // Calculate completion date
    const nextDepositDate = getNextDepositDate({
      dayOfPeriod: scheduledDepositConfig.dayOfPeriod ?? 1,
      secondaryDayOfPeriod:
        scheduledDepositConfig.secondaryDayOfPeriod ?? undefined,
      periodType: scheduledDepositConfig.periodType,
      startAt: DateOnly.fromDateTz(
        scheduledDepositConfig.startAt,
        BUSINESS_TIMEZONE,
      ),
    });
    // Calculate total days needed
    let depositsLeft = ra.depositsNeeded.minus(1);
    let lastDepositDate = nextDepositDate;
    while (depositsLeft.gt(0)) {
      lastDepositDate = getNextDepositDateOnly({
        referenceDate: lastDepositDate.nextDay(),
        periodType: scheduledDepositConfig.periodType,
        dayOfPeriod: scheduledDepositConfig.dayOfPeriod ?? 1,
        secondaryDayOfPeriod:
          scheduledDepositConfig.secondaryDayOfPeriod ?? undefined,
        lastDepositDate,
      });
      depositsLeft = depositsLeft.minus(1);
    }

    const totalDaysNeeded = lastDepositDate.diff(nextDepositDate, "days");

    const today = DateOnly.now(BUSINESS_TIMEZONE);

    const daysUntilDepositStarts = Decimal.max(
      nextDepositDate.diff(today, "days"),
      0,
    ).toNumber();

    return {
      allocationSubAccountId: ra.allocationSubAccountId,
      daysUntilCompletionFromToday:
        scheduledDepositConfig.periodType === ScheduledDepositPeriodType.None
          ? 0
          : totalDaysNeeded + daysUntilDepositStarts,
      depositsNeeded: ra.depositsNeeded.toNumber(),
      dateUntilCompletion:
        scheduledDepositConfig.periodType === ScheduledDepositPeriodType.None
          ? null
          : lastDepositDate,
    };
  });
};

export const convertAllocationConfigCashTransfersToInput = (
  cashTransfers?: AllocationConfigCashTransfer[] | null,
): AllocationConfigCashTransferInput[] => {
  return (
    cashTransfers?.map((ct) => ({
      percentage: ct.percentage,
      allocationSubAccountId: ct.allocationSubAccountId,
    })) ?? []
  );
};
