import {
  AllocationRebalanceAmount,
  amountRequiredForRebalance,
  buyAndSellToRebalance,
  ZERO,
} from "@frec-js/common";
import Decimal from "decimal.js";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";

import {
  AllocationConfigFragment,
  AllocationConfigType,
  AvailabilityLevel,
  useSubAccountPortfolioAggregatesQuery,
} from "../../generated/graphql";
import { convertSubAccountPortfolioAggregatesToAllocationValue } from "../../hooks";
import { TransferAccount } from "../../types";

/**
 * Client-side only enum to represent the type of rebalance.
 */
export enum AllocationRebalanceType {
  Cash = "cash", // Deposit cash into allocation
  LineOfCredit = "lineOfCredit", // Borrow from PLOC and deposit into allocation
  BuyAndSell = "buyAndSell", // Sell overweight accounts and buy underweight accounts
}

export interface AllocationRebalanceProviderContextProps {
  loading: boolean;
  // SetupLayout
  stepReached: number;
  setStepReached: (step: number) => void;
  // Provider states
  rebalanceType?: AllocationRebalanceType;
  setRebalanceType: (rebalanceType: AllocationRebalanceType) => void;
  account?: TransferAccount;
  setAccount: (account?: TransferAccount) => void;
  // Data values
  allocationConfig: AllocationConfigFragment;
  rebalanceAmounts: AllocationRebalanceAmount[];
  totalRebalanceAmount: Decimal;
  rebalanceTrades: AllocationRebalanceAmount[];
}

const AllocationRebalanceContext =
  createContext<AllocationRebalanceProviderContextProps>({
    loading: false,
    // SetupLayout
    stepReached: 0,
    setStepReached: () => undefined,
    // Provider states
    rebalanceType: undefined,
    setRebalanceType: () => undefined,
    account: undefined,
    setAccount: () => undefined,
    // Data values
    allocationConfig: {
      id: "",
      clearingAccountId: "",
      cashTransfers: [],
      type: AllocationConfigType.PortfolioRebalance,
    },
    rebalanceAmounts: [],
    totalRebalanceAmount: ZERO,
    rebalanceTrades: [],
  });

export const useAllocationRebalance = () => {
  const context = useContext<AllocationRebalanceProviderContextProps>(
    AllocationRebalanceContext,
  );
  return context;
};

type AllocationRebalanceProviderProps = PropsWithChildren<{
  clearingAccountId?: string;
  allocationConfig: AllocationConfigFragment;
}>;

export const AllocationRebalanceProvider = ({
  clearingAccountId,
  allocationConfig,
  children,
}: AllocationRebalanceProviderProps) => {
  // Fetch portfolio aggregates for the clearing account.
  const { data, loading } = useSubAccountPortfolioAggregatesQuery({
    variables: {
      input: {
        availability: AvailabilityLevel.LastSettled,
        clearingAccountId: clearingAccountId as string,
      },
    },
    skip: !clearingAccountId,
  });

  const portfolioAllocationValue = useMemo(
    () =>
      convertSubAccountPortfolioAggregatesToAllocationValue(
        data?.subAccountPortfolioAggregates ?? [],
      ),
    [data?.subAccountPortfolioAggregates],
  );

  // Calculate the amount required to rebalance the allocation.
  // Sort by amount required, so that the largest amount is first.
  const rebalanceAmounts = useMemo(() => {
    return amountRequiredForRebalance(portfolioAllocationValue, {
      cashTransfers: allocationConfig.cashTransfers ?? [],
    }).sort((a, b) => b.amountRequired.minus(a.amountRequired).toNumber());
  }, [portfolioAllocationValue, allocationConfig.cashTransfers]);

  const totalRebalanceAmount = useMemo(() => {
    return rebalanceAmounts.reduce(
      (acc, rb) => acc.plus(rb.amountRequired),
      ZERO,
    );
  }, [rebalanceAmounts]);

  const rebalanceTrades = useMemo(() => {
    return buyAndSellToRebalance(portfolioAllocationValue, {
      cashTransfers: allocationConfig.cashTransfers ?? [],
    });
  }, [portfolioAllocationValue, allocationConfig]);

  // SetupLayout
  const [stepReached, setStepReached] = useState(0);
  // Provider states
  const [rebalanceType, setRebalanceType] = useState<AllocationRebalanceType>();
  const [account, setAccount] = useState<TransferAccount>();

  const value = useMemo(
    () => ({
      loading,
      // SetupLayout
      stepReached,
      setStepReached,
      // Provider states
      rebalanceType,
      setRebalanceType,
      // the account is only relevant for the cash rebalance type
      account:
        rebalanceType === AllocationRebalanceType.Cash ? account : undefined,
      setAccount,
      // Data values
      allocationConfig,
      rebalanceAmounts,
      totalRebalanceAmount,
      rebalanceTrades,
    }),
    [
      loading,
      stepReached,
      setStepReached,
      rebalanceType,
      setRebalanceType,
      account,
      setAccount,
      allocationConfig,
      rebalanceAmounts,
      totalRebalanceAmount,
      rebalanceTrades,
    ],
  );

  return (
    <AllocationRebalanceContext.Provider value={value}>
      {children}
    </AllocationRebalanceContext.Provider>
  );
};
